<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="container vert-offset-top-1">
        <div class="row justify-content-center">
            <div class="col s12 m10 l8 offset-m1 offset-l2">

                <div class="card col s12 vert-offset-top-1 padding-top-half">

                    <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                            <router-link class="btn-small homeboy-charcoal-black white-text margin-right-half" to="/login">
                                <i class="material-icons">arrow_back</i>
                            </router-link>
                        <span >
                            Need help activating your account?
                        </span>
                    </div>
                    <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                        <div class="row">
                            <span class="font-12 homeboy-charcoal-black-text">Have OTP has already? If yes <router-link class="underline homeboy-gold-text" to="/confirm/mobile">click here to activate your account</router-link></span>
                        </div>
                        <div class="row">
                            <form>
                                <div class="row vert-offset-bottom-1 font-12">
                                    Enter your mobile below to receive a new OTP
                                </div>
                                <div class="row vert-offset-bottom-half">
                                    <div class="col s12 no-padding">
                                        <div class="row col s12 m5 vert-offset-top-1">
                                            <custom-select-component ref="country" v-model="selected_country" :options="countries"  title="Type to select your Country"></custom-select-component>
                                        </div>
                                        <div class="col s12 m7 vert-offset-bottom-half">
                                            <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" required="required" autocomplete="off" minlength="6" maxlength="15"></input-component>
                                        </div>

                                    </div>
                                </div>

                                <div class="row vert-offset-bottom-half">
                                    <div class="col s12 m6 offset-m3">
                                        <a @click.prevent="confirmSendOtp" class="btn btn-block homeboy-gold-text homeboy-charcoal-black  font-12">
                                            Send Me OTP
                                        </a>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../http-common"
    import { bus } from "../../main"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import {mapState} from "vuex"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"


    export default {
        name: 'SendOtp',
        components:{
            InputComponent,CustomSelectComponent
        },
        data(){
            return{
                mobile:"",
                selected_country:"",
                processing: false
            }
        },
        created(){
            console.log("LOGGING HERE");
            this.getCountries();

            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                console.log("Data: "+_data);
                if(_data == "confirmSendOtp")
                {
                    console.log("Trigger Save Pin reset");
                    this.sendOtp();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
            //this.confirmPinChange();
        },
        mounted(){
            //this.$refs.country.initializeSelect();
            this.selected_country ="BW";


        },
        computed:{
            ...mapState({
                countries:state=>state.loyaltolocal.l2l_countries
            })
        },
        methods:{

            confirmSendOtp: function()
            {
                if(this.validateForm())
                {
                    this.$store.dispatch("SET_ACTION", "confirmSendOtp");
                }
            },
            validateForm: function()
            {
                let _arrErrors = new Array();

                if(typeof this.mobile === "undefined" || this.mobile === null || this.mobile.length <= 0)
                {
                    _arrErrors.push("Invalid Mobile Number");
                }
                else if(this.mobile.length > 15)
                {
                    _arrErrors.push("Mobile number too long, cannot exceed 15 characters");
                }

                if(_arrErrors.length > 0)
                {
                    this.$store.dispatch('GET_MESSAGES',_arrErrors);
                    return false;
                }

                return true;
            },

            sendOtp:function(){
                if(this.processing === true)
                {
                    return false;
                }
                this.processing = true;
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: '/customers/request/otp/mobile',
                        data:{
                            msisdn:this.mobile,
                            country:this.selected_country,
                            source: this.$source

                        },
                        method: 'POST',
                        timeout: 30000
                    }).then
                    (resp => {

                        this.$store.dispatch('STOP_PROCESSING');
                        //this.customer_services = resp.data.data;
                        console.log("DATA : "+resp.data.data);
                        this.processing = false;

                        this.$router.push("/confirm/mobile");


                        resolve(resp)
                    }).catch((response) =>
                    {
                        console.log("ErrResponse: "+JSON.stringify(response.data));
                        this.$store.dispatch('STOP_PROCESSING');
                        this.$store.dispatch('GET_MESSAGES',response.data.messages);
                        this.processing = false;
                        //reject(err)
                    })
                })
            },

            getCountries:function () {
                this.$store.dispatch("GET_L2L_COUNTRIES_REQUEST");
            }

        }
    }
</script>
